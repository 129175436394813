@import '../../../../styles/apx.scss';

.infodesk {

  padding: $apx-m-8 $apx-m-16;
  border-radius: $apx-m-8;
  background: var(--color-white);
  margin-bottom: $apx-m-32;

  &__title {

    font-family: 'SFPro300';
    &__ghost { color: var(--color-gray-ghost); }

  }

  &--opened {

    .infodesk__arrow {
      
      margin-top: -$apx-m-8;
      transform: rotate(180deg);
    
    }
    
    div {
      
      &:first-child { margin-bottom: $apx-m-8; }
      &:last-child { display: block !important; }
    
    }
  
  }

  div {
    
    &:last-child { display: none; }
  
  }

  &__icon {
  
    $size: $apx-m-28;
    width: $size;
    height: $size;
    margin-right: $apx-m-8;
  
  }

  &__arrow {
  
    min-width: $apx-m-6;
    min-height: $apx-m-10;
  
  }

}