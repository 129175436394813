/*ЦВЕТА*/
:root {

  --color-white: #FFF;
  --color-gray: #828282;
  --color-gray-ghost: #BEBEBE;
  --color-gray-dark: #ECECEC;
  --color-gray-darker: #EAEAEA;
  --color-gray-light: #C8C7CB;
  --color-gray-lighter: #F7F7F7;
  --color-gray-lightest: #F4F4F4;
  --color-primary: #000000;
  --color-primary-light: #333333;
  --color-secondary: #3c3c4386;
  --color-purple: #BB6BD9;
  --color-purple-light: #A94CDF;
  --color-violet: #AA4CE0;
  --color-violet-light: #aa4ce088;
  --color-orange: #FF9500;
  --color-red: #EB5757;
  --color-green: #27AE60;

}