@import '../../../styles/apx.scss';

.content {

  width: 100%;
  height: 101vh;
  padding: 0 $apx-m-16;
  background: #F4F4F4;

}

.title {

  font-family: 'SFPro600';
  color: var(--color-primary);
  letter-spacing: -$apx-m-2 !important;
  margin-bottom: $apx-m-26;

}

.button { margin-top: $apx-m-28; }

.textfield { margin: $apx-m-8 0; }