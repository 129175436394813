@import '../../../../styles/apx.scss';

.carcas {

  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  background: var(--color-white);

}

.header {

  width: 100%;
  height: $apx-m-65;
  padding-top: $apx-m-5;
  padding-bottom: $apx-m-30;

  &__chat { height: $apx-m-53; }

  &__gray {
    
    background-color: #F4F4F4 !important;
    &__light { background-color: #F7F7F7 !important; }
  
  }

  &__title {
  
    color: var(--color-primary);
  
  }

  &__subtitle {
  
    color: var(--color-secondary);
    font-family: 'SFPro400' !important;
  
  }

  &__menu {
  
    top: $apx-m-10;
    right: $apx-m-16;
    $size: $apx-m-28;
    width: $size;
    height: $size;
    border-radius: 100%;
    border: $apx-m-3 solid var(--color-purple);

    &__point {
         
      $size: 0.65vw;
      width: $size;
      height: $size;
      border-radius: 100%;
      margin-left: 0.35vw;
      margin-right: 0.35vw;
      background: var(--color-purple);

      &__central { left: 0.015vw; }

    }

    &__avatars {
    
      top: $apx-m-10;
      right: $apx-m-10;

      display: flex;

    
    }

    &__avatar {

      $size: $apx-m-37;

      width: $size;
      height: $size;

      border-radius: 50%;
      background: var(--color-violet);
      overflow: hidden;

      border: $apx-m-2 solid var(--color-purple);

      display: flex;
      justify-content: center;
      align-items: center;

      font-size: $apx-m-13;

      color: var(--color-white);

      &:not(:first-child){

        margin-left: -$apx-m-15;

      }

      img {

        width: 100%;
        height: 100%;
        object-fit: cover;

      }

    }

  }

  &__back {
  
    top:  $apx-m-16;
    left: $apx-m-8;
    font-family: 'SFPro400';
    color: var(--color-violet);
    
    &__arrow {
    
      width: $apx-m-12;
      height: $apx-m-22;
      margin-right: $apx-m-6;
    
    }
  
  }

  &__cancel {
  
    top:  $apx-m-16;
    left: $apx-m-16;
    font-family: 'SFPro400';
    color: var(--color-purple);
  
  }

}