@import '/src/styles/apx.scss';

.offer {

  padding: $apx-m-16;
  margin-bottom: $apx-m-8;
  border-radius: $apx-m-8;
  background: var(--color-white);


  &__name {
  
    font-family: 'SFPro600';
    color: var(--color-primary-light);
    margin-bottom: $apx-m-8;
  
  }

  &__deals {
  
    top: $apx-m-8;
    font-family: 'SFPro400';
    color: var(--color-primary-light);
    margin-bottom: $apx-m-8;
    white-space: nowrap !important;

    span {
      
      font-family: 'SFPro300';
      margin-left: $apx-3;
      margin-right: $apx-3;
      &:nth-child(1) { margin-left: unset; color: #27AE60; }
      &:nth-child(2) { color: #BEBEBE; }
      &:nth-child(3) { color: #EB5757; }

    }

    &__graytext {
      
      color: #BEBEBE !important;

      &__thin {
        
        font-family: 'SFPro300';
        margin: $apx-m-5 0 $apx-m-16;
      
      }
    
    }
  
  }



  &__liner {
  
    width: 100%;
    margin: $apx-m-12 0;
    border: $apx-1 solid #F4F4F4;
  
  }

  &__arrow {

    top: $apx-1;
    width: $apx-m-14;
    height: $apx-m-19;
    margin-left: $apx-m-25;
    margin-right: $apx-m-25;
  
  }

}