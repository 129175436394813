@import '../../../../styles/apx.scss';

.exchanger {

  height: $apx-m-116;
  padding: $apx-m-16;
  border-radius: $apx-m-8;
  background: var(--color-gray-darker);

  &__with_payment { margin-bottom: $apx-m-50; }

  &__title {
  
    font-size: $apx-m-16;
    line-height: $apx-m-21;
    color: var(--color-gray);
    text-transform: uppercase;
    margin-bottom: $apx-m-16;
  
  }

  .exchanger__currency  .exchanger__currency__name {
    margin-left: $apx-m-8;
  }

  &__input {
  
    height: $apx-m-48;
    padding: $apx-m-4;
    border-radius: $apx-m-8;
    background: var(--color-white);

    .exchanger__currency__name {
    
      color: var( --color-primary);
      margin-right: $apx-m-22;
    
    }

  
  }

  &__currency {
  
    padding: $apx-m-8;
    height: $apx-m-40;
    border-radius: $apx-m-8;
    background: var(--color-gray-dark);

    &--opened {
    
      border-bottom-left-radius: unset;
      border-bottom-right-radius: unset;
      border-bottom: $apx-m-2 solid var(--color-gray-darker);

      .exchanger__currency__arrow { transform: rotate(180deg); }
    
    }

    &__list {
  
      z-index: 10;
      top: $apx-m-39;
      left: $apx-m-4;
      min-width: $apx-m-120;
      display: none;
      background: var(--color-gray-dark);
  
      &--opened {
      
        display: block;
        border-bottom-left-radius: $apx-m-8;
        border-bottom-right-radius: $apx-m-8;
      
      }

      &__item {
      
        width: 100%;
        height: $apx-m-50;
        padding-left: $apx-m-8;
        border-bottom: $apx-m-2 solid var(--color-gray-darker);
        &:last-child { border-bottom: unset; } 
      
      }

    }
  
    &__icon {
    
      $size: $apx-m-28;
      width: $size;
      height: $size;
    
    }

    &__name {
    
      color: var( --color-primary);
      margin-right: $apx-m-22;
      margin-left: 0 ;
    
    }

    &__arrow {
    
      min-width: $apx-m-7;
      min-height: $apx-m-12;
      position: relative;
      top: -$apx-1;
    
    }

    &__value {
    
      height: $apx-m-20 !important;
      color: var( --color-primary-light) !important;
      margin-right: $apx-m-8;
      font-size: $apx-m-16;
      line-height: $apx-m-24;
      font-family: 'SFPro500' !important;
      text-align: end;
      padding: 0 !important;
      
      &::placeholder {
      
        font-size: $apx-m-16;
        line-height: $apx-m-24;
        font-family: 'SFPro500' !important;
        text-align: end;
        padding: 0 !important;
        color: var( --color-gray-ghost) !important;
      
      }
    
    }

    &__type {
    
      font-family: 'SFPro400' !important;
      color: var( --color-gray-ghost);
    
    }

    &__container {
    
      justify-content: flex-end !important;
    
    }

  }
  

}

.pay_button {
  margin-left: auto;
}

.payment {

  position: relative;

  width: $apx-m-360;
  padding: $apx-m-4;
  margin-top: $apx-m-24;
  margin-left: -$apx-m-20;
  border-radius: $apx-m-8;
  background: var(--color-white);

  &__title {
  
    border-radius: $apx-m-8;
    padding: $apx-m-10 $apx-m-12;
    background: var(--color-gray-dark);
  
  }

  &__option {
  
    margin-left: auto !important ;
    font-family: 'SFPro400';
    color: var(--color-violet);
    margin-right: $apx-m-8;

    display: flex;
    align-items: center;
    gap: $apx-m-8;
  
  }

  &__arrow {
   
    margin-right: $apx-m-8;
    min-width: $apx-m-7;
    min-height: $apx-m-12;
    position: relative;
    top: -$apx-1;
  
  }
  
  .pay_icon {

    width: $apx-m-28 !important;
    height: $apx-m-28 !important;

  }
  .pay_list {

    position: absolute;
    z-index: 10;

    max-height: $apx-m-250;
    overflow: auto;

    right: 0;
    top: $apx-m-25;
    padding: $apx-m-10 0;
    background: var(--color-white);
    border-radius: $apx-m-10;
    box-shadow: 0 0 $apx-m-20 #0000005e;

    list-style: none;
    display: flex;
    flex-direction: column;
    min-width: $apx-m-150;


    li {

      padding: $apx-m-5 $apx-m-20;
      width: 100%;


    }

  }

}

.select_section {


  .layout {
    
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: #00000078;

    z-index: -5;
    visibility: hidden;
    opacity: 0;
    transition: opacity .2s;
    
  }

  .layout.active {

    z-index: 15;
    visibility: visible;
    opacity: 1;

  }

  ul {
  
    border-radius: $apx-m-10 $apx-m-10 0 0 ;
    margin: 0;
    list-style: none;
    position: fixed;
    background: var(--color-white);
    z-index: 21;
    left: 0;
    right: 0;
    bottom: 0;
  
    height: $apx-m-350;
    overflow: auto;

    padding: $apx-m-20 0;


    li {

      padding: $apx-m-10 $apx-m-40;
      border-top: $apx-m-1 solid var(--color-violet);
      display: flex !important;
      align-items: center;
      gap: $apx-m-10;
      
      &:last-child {

        border-bottom: $apx-m-1 solid var(--color-violet);

      }
      

    }

    input {

      width: 90%;
      margin: 0 auto;
      display: block;
      padding: $apx-m-10 $apx-m-20;
      margin-bottom: $apx-m-10;
      border-radius: $apx-m-5;
      border: $apx-m-1 solid var(--color-violet);

    }
  
  }

}
