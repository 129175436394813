@import '../../../styles/apx.scss';

.title {

  text-align: center;
  font-family: 'SFPro700';
  color: var(--color-primary-light);
  background: var(--color-gray-lighter);
  padding-bottom: $apx-m-16; 

}

.nothing {

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $apx-m-15;
  width: fit-content;

  font-size: $apx-m-40;
  margin: 0 auto;
  margin-top: $apx-m-20;

}

.container {

  padding: $apx-m-16;
  background: var(--color-gray-lighter); 
  min-height: 100vh;

  .more_button {

    background: none;
    margin: $apx-m-16 auto $apx-m-16 auto;
    border: none;
    font-size: $apx-m-15;
    cursor: pointer;
    display: block;
    color: var(--color-purple);
    
    &:active {

      color: var(--color-violet);

    }


  }

}