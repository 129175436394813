@import '../../../../styles/apx.scss';

.textfield {

  width: 100%;
  height: $apx-m-50;
  border-radius: $apx-m-8;
  padding: $apx-m-15;
  border: unset;
  outline: none;
  font-size: $apx-m-16;
  line-height: $apx-m-21;
  color: var(--color-gray);
  font-family: 'SFPro400';
  
  &--rounded { border-radius: $apx-m-16; }

  &::placeholder {
  
    font-family: 'SFPro300';
    font-size: $apx-m-16;
    line-height: $apx-m-21;
    color: var(--color-gray-light);
    opacity: 1;
  
  }

}