@import '../../../styles/apx.scss';

.content {

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  background: var(--color-white);
  overflow: auto;
  height: 100%;

  img {

    width: 100%;
    height: fit-content;

    object-fit: contain;

  }

  h2 {

    color: var(--color-secondary);
    padding: $apx-m-15;
    text-align: center;
    margin-bottom: auto;

  }

}