@import '../../../../styles/apx.scss';

.button {

  width: 100%;
  margin: 0 auto;
  display: flex;
  place-self: center;
  align-items: center;
  justify-content: center;
  height: $apx-m-48;
  font-size: $apx-m-16;
  line-height: $apx-m-21;
  border-radius: $apx-m-8;
  color: var(--color-white);
  background: var(--color-violet);
  font-family: 'SFPro300';
  text-align: center;
  border: unset;
  outline: none;

  &__small {
  
    width: $apx-m-155;
    height: $apx-m-48;
  
  }
  
  &__light { background: var(--color-violet); }
  
}

.disabled {

  opacity: .3;
  pointer-events: none !important;

}