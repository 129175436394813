@import '../../../styles/apx.scss';

.title {

  text-align: center;
  font-family: 'SFPro700';
  color: var(--color-primary-light);
  background: var(--color-gray-lighter);
  padding-bottom: $apx-m-16; 

}

.container {

  padding: $apx-m-16;
  background: var(--color-gray-lighter); 

}

.bid {

  padding: $apx-m-16;
  border-radius: $apx-m-8;
  margin-bottom: $apx-m-14;
  background: var(--color-white);

  &__left {
    
    width: $apx-m-250;
    &__short { width: $apx-m-156; }
  
  }

  &__name {
  
    font-family: 'SFPro700';
    color: var(--color-primary-light);
    margin-bottom: $apx-m-8;
  
  }

  &__deals {
  
    top: $apx-m-8;
    font-family: 'SFPro400';
    color: var(--color-primary-light);
    margin-bottom: $apx-m-8;
    white-space: nowrap !important;

    &__graytext {
      
      color: #BEBEBE !important;

      &__thin {
        
        font-family: 'SFPro400';
        margin-bottom: $apx-m-8;
      
      }

      &__positive { color: #27AE60 !important; }
      &__negative { color: #EB5757 !important; }
      &__purple { 
        
        margin-top: auto;
        color: #AA4CE0 !important;
       
      }
    
    }

    &_regular {
    
      font-family: 'SFPro400';
      color: var(--color-primary-light);

    }
  
  }

  &__link_icon {
  
    cursor: pointer;
    $size: $apx-m-24;
    width: $size;
    height: $size;
    margin-right: $apx-m-6;
  
  }

  &__liner {
  
    width: 100%;
    margin: $apx-m-12 0;
    border: $apx-1 solid #F4F4F4;
  
  }

  &__arrow {

    top: $apx-1;
    width: $apx-m-14;
    height: $apx-m-19;
    margin-left: $apx-m-25;
    margin-right: $apx-m-25;
  
  }

}

.button { margin: $apx-m-16 0; }

.note {
  
  font-family: 'SFPro400';
  text-align: center !important;

}