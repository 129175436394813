.text-60-m {


  font-size: $apx-m-60;
  line-height: $apx-m-65;


}

.text-55-m {


  font-size: $apx-m-55;
  line-height: $apx-m-60;


}

.text-50-m {


  font-size: $apx-m-50;
  line-height: $apx-m-55;


}


.text-49-m {


  font-size: $apx-m-49;
  line-height: $apx-m-54;


}


.text-48-m {


  font-size: $apx-m-48;
  line-height: $apx-m-53;


}


.text-47-m {


  font-size: $apx-m-47;
  line-height: $apx-m-52;


}


.text-46-m {


  font-size: $apx-m-46;
  line-height: $apx-m-51;


}


.text-45-m {


  font-size: $apx-m-44;
  line-height: $apx-m-50;


}


.text-44-m {


  font-size: $apx-m-44;
  line-height: $apx-m-49;


}


.text-43-m {


  font-size: $apx-m-43;
  line-height: $apx-m-48;


}


.text-42-m {


  font-size: $apx-m-42;
  line-height: $apx-m-47;


}


.text-41-m {


  font-size: $apx-m-41;
  line-height: $apx-m-46;


}


.text-40-m {


  font-size: $apx-m-40;
  line-height: $apx-m-45;


}


.text-39-m {


  font-size: $apx-m-39;
  line-height: $apx-m-44;


}




.text-38-m {


  font-size: $apx-m-38;
  line-height: $apx-m-43;


}




.text-37-m {


  font-size: $apx-m-37;
  line-height: $apx-m-42;


}




.text-36-m {


  font-size: $apx-m-36;
  line-height: $apx-m-41;


}




.text-35-m {


  font-size: $apx-m-35;
  line-height: $apx-m-40;


}


.text-34-m {


  font-size: $apx-m-34;
  line-height: $apx-m-39;


}


.text-33-m {


  font-size: $apx-m-33;
  line-height: $apx-m-38;


}


.text-32-m {


  font-size: $apx-m-32;
  line-height: $apx-m-37;


}


.text-31-m {


  font-size: $apx-m-31;
  line-height: $apx-m-36;


}


.text-30-m {


  font-size: $apx-m-30;
  line-height: $apx-m-35;


}


.text-29-m {


  font-size: $apx-m-29;
  line-height: $apx-m-34;


}


.text-28-m {


  font-size: $apx-m-28;
  line-height: $apx-m-33;


}


.text-27-m {


  font-size: $apx-m-27;
  line-height: $apx-m-32;


}


.text-26-m {


  font-size: $apx-m-26;
  line-height: $apx-m-31;


}


.text-25-m {


  font-size: $apx-m-25;
  line-height: $apx-m-30;


}


.text-24-m {


  font-size: $apx-m-24;
  line-height: $apx-m-29;


}


.text-23-m {


  font-size: $apx-m-23;
  line-height: $apx-m-28;


}


.text-22-m {


  font-size: $apx-m-22;
  line-height: $apx-m-27;


}


.text-21-m {


  font-size: $apx-m-21;
  line-height: $apx-m-26;


}


.text-20-m {


  font-size: $apx-m-20;
  line-height: $apx-m-25;


}


.text-19-m {


  font-size: $apx-m-19;
  line-height: $apx-m-24;


}


.text-18-m {


  font-size: $apx-m-18;
  line-height: $apx-m-23;


}


.text-17-m {


  font-size: $apx-m-17;
  line-height: $apx-m-22;


}


.text-16-m {


  font-size: $apx-m-16;
  line-height: $apx-m-21;


}


.text-15-m {


  font-size: $apx-m-15;
  line-height: $apx-m-20;


}


.text-14-m {


  font-size: $apx-m-14;
  line-height: $apx-m-19;


}


.text-13-m {


  font-size: $apx-m-13;
  line-height: $apx-m-18;


}


.text-12-m {


  font-size: $apx-m-12;
  line-height: $apx-m-17;


}


.text-11-m {


  font-size: $apx-m-11;
  line-height: $apx-m-16;


}


.text-10-m {


  font-size: $apx-m-10;
  line-height: $apx-m-15;


}


.text-9-m {


  font-size: $apx-m-9;
  line-height: $apx-m-14;


}


.text-8-m {


  font-size: $apx-m-8;
  line-height: $apx-m-13;


}


.text-7-m {


  font-size: $apx-m-7;
  line-height: $apx-m-12;


}


.text-6-m {


  font-size: $apx-m-6;
  line-height: $apx-m-11;


}


.text-5-m {


  font-size: $apx-m-5;
  line-height: $apx-m-10;


}