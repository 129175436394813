@import '../../../../styles/apx.scss';

.faq {

  border-radius: $apx-m-8;
  background: var(--color-white);
  margin-bottom: $apx-m-32;
  
  button {
    
    padding: $apx-m-16;
    width: 100%;
    display: flex;
    background: none;
    border: none;
    
  }

  &--opened {

    .faq__arrow {
      
      margin-top: -$apx-m-26;
      transform: rotate(180deg);
    
    }
    
    div {
      
      &:first-child { margin-bottom: $apx-m-24; }
      &:last-child { display: block !important; }
    
    }
  
  }

  div {
    
    &:last-child { display: none; }
  
  }

  &__icon {
  
    $size: $apx-m-28;
    width: $size;
    height: $size;
    margin-right: $apx-m-8;
  
  }

  &__arrow {
  
    width: $apx-m-7;
    height: $apx-m-12;
  
  }

}