@import '../../../styles/apx.scss';

.content {

  width: 100%;
  height: 101vh;
  padding: 0 $apx-m-16;
  background: #F4F4F4;

}

.title {

  font-family: 'SFPro600';
  color: var(--color-primary);
  letter-spacing: -$apx-m-2 !important;
  margin-bottom: $apx-m-26;

}

.textfield {
  
  height: unset;
  margin: $apx-m-16 0 $apx-m-8;

}

.pending {
  opacity: .5;
}

.button { margin-top: $apx-m-28; }


.rate__button.active {

  opacity: 1  !important;


}

.rate__button {

  width: $apx-m-120;
  height: $apx-m-42;
  margin-right: $apx-m-4;
  border-radius: $apx-m-8;
  border: $apx-m-1 solid var(--color-gray-ghost);
  opacity: .5;

  &__positive {
  
    background: #27ae5f27;
    border: unset !important;
    .rate__button__name { color: var(--color-green); }
  
  }

  &__negative {
  
    background: #eb575728;
    border: unset !important;
    .rate__button__name { color: var(--color-red); }
  
  }

  &__name {
  
    font-family: "SFPro400";
    color: var(--color-gray-ghost);
  
  }

}