@import '../../../styles/apx.scss';

.container {

  padding: $apx-m-32 $apx-m-16;
  background: var(--color-gray-lighter);

  &__changer {

    display: flex;
    flex-direction: column;

    &--reversed {
    
      flex-direction: column-reverse !important;
    
    }

  }

}

.container {

  display: flex;
  flex-direction: column;
  padding: $apx-m-32 $apx-m-16;
  background: var(--color-gray-lighter);

  &--reversed {
  
    flex-direction: row-reverse !important;
  
  }

}

.shifter {

  z-index: 5;
  $size: $apx-m-40;
  width: $size;
  height: $size;
  margin-top: -$apx-m-8;
  margin-bottom: -$apx-m-8;
  border-radius: $apx-m-8;
  box-shadow: $apx-m-1 $apx-m-1 $apx-m-15 $apx-m-5 rgba(10, 10, 10, 0.05);
  background: var(--color-white);

  &__icon {

    $size: $apx-m-24;
    min-width: $size;
    min-height: $size;
  
  }

}

.button { margin: $apx-m-16 0 $apx-m-32; }