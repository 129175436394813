@import '../../../styles/apx.scss';

.chat {

  width: 99vw;
  height: 93vh;
  overflow-Y: auto;
  overflow-X: hidden;
  padding-top: $apx-m-152;
  padding-left: $apx-m-16;
  padding-right: $apx-m-16;
  background: var(--color-gray-lightest);

  ::-webkit-scrollbar {
    width: $apx-m-4;
    height: $apx-m-2;
    background-color: var(--color-violet-light);

  }

  ::-webkit-scrollbar-thumb {
    width: $apx-m-3;
    height: $apx-m-2;
    border-radius: $apx-m-15;
    background-color: var(--color-violet);

  }

  &__controls {
  
    &__memo {
    
      top: 0;
      left: 0;
      width: 100%;
      height: $apx-m-56;
      padding: $apx-m-16;
      background: var(--color-white);
      border-top: $apx-m-1 solid #b2b2b555;
      border-bottom: $apx-m-1 solid #b2b2b525;

      &__text {
      
        opacity: 0.5;
        font-family: 'SFPro400';
        color: var(--color-primary-light);
      
      }

      &__arrow {
      
        width: $apx-m-13;
        height: $apx-m-11;
        margin: 0 $apx-m-4;
      
      }

      .mute {
        position: relative;
      }

      .mute::after {

        content: '';
        position: absolute;
        width: $apx-m-15;
        height: $apx-m-1;

        display: block;

        left: -$apx-m-1;
        top: 0;
        bottom: $apx-m-3;
        margin: auto;

        transform: rotate(-30deg);

        background: var(--color-violet);


      }
    
    }

    &__panel {
    
      top: $apx-m-56;
      left: 0;
      width: 100%;
      height: $apx-m-56;
      padding: $apx-m-8 $apx-m-14 $apx-m-8 $apx-m-20;
      background: var(--color-white);

      &__graybox {

        padding: $apx-m-4 $apx-m-5;
        border-radius: $apx-m-4;
        background: var(--color-gray-lightest);

        &__text {
        
          margin-left: $apx-m-8;
          color: var(--color-gray-ghost);
          max-width: $apx-m-50;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        
        }

        &__icon {
        
          $size: $apx-m-20;
          min-width: $size;
          height: $size;
        
        }

      }

      &__layers {
        
        opacity: 0.65;
        $size: $apx-m-20;
        min-width: $size;
        height: $size;
        margin: 0 $apx-m-10;
      
      }

      &__warning {     
        
        $size: $apx-m-38;
        width: $size;
        height: $size;
        border-radius: $apx-m-8;
        background: #eb575727;
        margin: 0 auto;

        &__icon {
        
          $size: $apx-m-20;
          width: $size;
          height: $size;         
        
        }

      }

      .button {
        background: none;
        border: none;
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;
      }
    
    }

    &__userpanel {
    
      left: 0;
      bottom: 0;
      width: 100%;
      z-index: 10;
      padding: $apx-m-16 $apx-m-8;
      background: var(--color-white);
      flex-direction: column;
      gap: $apx-m-10;
      align-items: flex-start !important;

      &__attach {

        $size: $apx-m-33;
        width: $size;
        height: $size;
        margin-right: $apx-m-4;

      }

      &__send {

        $size: $apx-m-33;
        width: $size;
        height: $size;
        margin-left: $apx-m-4;

      }
    
    }
  
  }

  .controls_img {

    width: $apx-m-60;
    height: $apx-m-60 !important;
    object-fit: cover;
    border-radius: $apx-m-10;
    
  }

  .chat_controls_main {

    display: flex;
    width: 100%;

  }

  &__content {
  
    display: flex;
    flex-grow: 1;
    width: 100%;
    overflow: auto;
    margin-top: $apx-m-16;
    padding-bottom: $apx-m-100;
  
  }

  &__body {
  
    flex-grow: 1;
    overflow: hidden;
    padding-bottom: $apx-m-65;
  
  }


  &__body.chat_canwrite {

    padding-bottom: 0 !important;
    
  }

  .upload_file {

    input {

      display: none;

    }

  }

  .chat_time {
    opacity: .3;
  }

}

.deal_start {

  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
  gap: $apx-m-5;

}

.poopUp {
  padding: $apx-m-8;
  border-radius: $apx-m-10;
  overflow: hidden;

  .poopUp_title {
    text-align: center;
    font-size: $apx-m-14;
    margin-bottom: $apx-m-10;
  }

  .sub_title {
    font-size: $apx-m-10;
    opacity: .8;
    margin-bottom: $apx-m-5;
  }

  input {
    width: 100%;
    margin-bottom: $apx-m-20;
  }

  span {
    display: block;
  }



  button {
    width: 100%;
    border: none;
    background-color: var(--color-violet);
    padding: $apx-m-8;
    color: var(--color-white);
    border-radius: $apx-m-5;
  }

}


.link {
  background: none;
  border-radius: none;
  color: #ffea83 !important;
  text-decoration: underline;
  cursor: pointer;
  border: none;
  display: inline-block ;
  font-size: inherit;
  padding: 0;
}


.bot {

  &__text {
  
    margin: $apx-m-8 0;
    font-family: 'SFPro400';
    color: var(--color-gray-ghost);
    text-align: center;

    &__blacked {
    
      font-family: 'SFPro500' !important;
      color: var(--color-primary-light);
    
    }


    &__mp {
      
      margin: $apx-m-32 0;
      line-height: $apx-m-24 !important;
    
    }

    span {
    
      font-family: 'SFPro400';
      color: var(--color-primary-light);
    
    }
  
  }

}


.chat_time_black {
  opacity: 1 !important;
  font-family: "SFPro500" !important;
  font-size: 16px;
  color: #000;
}

.textfield {

  height: $apx-m-28;
  padding: 0 $apx-m-10;
  border: $apx-m-1 solid #e0e0e185;
  background: var(--color-gray-lightest);

}

.warning {

  &--off { display: none !important; }

  &__icon {
  
    $size: $apx-m-20;
    width: $size;
    height: $size;
    margin-bottom: $apx-m-8;
  
  }

  &__text {
  
    color: var(--color-red);

    &--unactive { display: none !important; }
  
  }

}

.buttons__container {

  width: $apx-m-385;
  margin-top: -$apx-m-24;
  margin-left: -$apx-m-16;
  padding-left: $apx-m-4;
  margin-bottom: $apx-m-12;

  &__double {
  
    margin-top: -$apx-m-16;
    padding-bottom: $apx-m-24;
    border-bottom: $apx-m-2 solid #D9D8DA;
  
  }

  
}

.top_del_button {
  width: fit-content;
  padding: $apx-m-5 $apx-m-8 !important;
  white-space: nowrap;
  margin-left: auto;
  margin-right: 0;
}



.messages {

  width: $apx-m-254;
  padding: $apx-m-8;
  border-radius: $apx-m-8;
  margin: $apx-m-4;
  
  &__in {
    
    background: var(--color-white) !important;
    justify-content: flex-end;
    border-bottom-left-radius: unset;
  
  }

  &__out {
    
    background: var(--color-violet) !important;
    justify-content: flex-start;
    place-self: flex-end;
    border-bottom-right-radius: unset;
  
  }
  

  .moder_item {
    gap: $apx-m-10;
    margin-top: $apx-m-8;
    span { margin: 0 !important; }
  }

  &__message, &__datetime {
  
    text-align: left;
    font-family: 'SFPro400';
    &__in { color: var(--color-primary-light) !important; }
    &__out { color: var(--color-white) !important; }
  
  }


  .message_time.from {

    color: var(--color-gray-ghost);
    margin-right: auto;
    margin-left: 0;
    margin-top: $apx-m-8;

  }



  .message_time {

    font-weight: 400;
    font-size: 12px;
    color: #fff;
    margin-left: auto;
    width: fit-content;

  }

  &__datetime__out { place-self: flex-end; }

  .message_file {

    max-height: $apx-m-260 !important;
    object-fit: cover;
    border-radius: $apx-m-10;
    margin-top: $apx-m-15;

  }
  
}