@import '../../../styles/apx.scss';

.content {

  width: 100vw;
  height: 100vh;

}

.title {

  font-size: $apx-m-62;
  line-height: $apx-m-66;
  width: 100%;
  padding-top: 45vh;
  text-align: center;
  font-family: 'SFPro500';
  color: var(--color-white);
  margin-bottom: $apx-m-24;
  letter-spacing: -$apx-m-4 !important;

}

.subtitle {

  width: 100%;
  text-align: center;
  font-family: 'SFPro300';
  color: var(--color-white);
  margin-bottom: $apx-m-24;
  margin-bottom: $apx-m-24;
  letter-spacing: 0.036em !important;

}