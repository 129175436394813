@import './apx.scss';
@import './text.scss';
@import './fonts.scss';
@import './brand.scss';

/* СБРОС СТАНДАРТНЫХ СТИЛЕЙ */
*,
::after,
::before {
 
  outline: 0 !important;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-tap-highlight-color: transparent !important;

}

body,
html {
 
  cursor: default;
  min-width: 100%;
  min-height: 100%;
  user-select: none;
  font-display: swap;
  overflow-x: hidden;
  overflow-y: hidden;
  -o-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  font-family: 'SFPro500';

  ::-webkit-scrollbar {
    width: $apx-m-4;
    height: $apx-m-2;
    background-color: var(--color-violet-light);
    display: none; 

  }

  ::-webkit-scrollbar-thumb {
    width: $apx-m-3;
    height: $apx-m-2;
    border-radius: $apx-m-15;
    background-color: var(--color-violet);
    display: none; 

  }
 
}
 
body,
textarea,
input,
span,
div,
h1,
h2,
h3,
h4,
h5,
h6,
p {
 
   margin: 0;
   padding: 0;
 
}
 
button,
input,
select,
textarea { font-family: inherit; }
 
textarea { resize: vertical; }
 
a,
a:hover,
a:visited {
 
  text-decoration: none;
  color: inherit;
  font-family: inherit !important;

}

.popup-content {
  background: rgb(255, 255, 255);
  width: 70%;
  padding: $apx-m-10;
  border-radius: $apx-m-10;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.content { padding: $apx-m-16; }

.background { background: linear-gradient( to bottom right, #AA4CE0 1%, #41126B 100%); }
 
/* ОБЩИЕ СТИЛИ */
 
.disabled {
 
  opacity: 0.5;
  pointer-events: none;
 
}

.width-full {
  width: 100% !important;
}

.pointer { cursor: pointer; }

.m-0 { margin: 0 auto ; }
.mt-m-15 { margin-top: $apx-m-15 !important; }
.mt-0 { margin-top: 0 !important; }

//стили дисплея
.none { display: none; }
.flex { display: flex; }
.block { display: block; }
.flex-col { flex-direction: column; }

//стили позиционирования
.fixed { position: fixed; }
.absolute { position: absolute; }
.relative { position: relative; }

//flex-стили
.flex-center {
 
  display: flex;
  align-items: center;
  justify-content: center;
 
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
}

.flex-full { flex: 1 1; }

.row { flex-direction: row; }
.column { flex-direction: column; }
.around { justify-content: space-around; }
.between { justify-content: space-between; } 
.center { justify-content: center; }
.start { justify-content: flex-start; }
.end { justify-content: flex-end; }
.items-end { align-items: flex-end; }
.items-start { align-items: flex-start; }
.items-center { align-items: center; }
.self-center { place-self: center; }

//стили текста
.text-left { text-align: left; }
.text-right { text-align: right; }
.text-center { text-align: center; }

.font-thin { font-weight: 100; }
.font-extralight { font-weight: 200; }
.font-light { font-weight: 300; }
.font-normal { font-weight: 400; }
.font-medium { font-weight: 500; }
.font-semibold { font-weight: 600; }
.font-bold { font-weight: 700; }
.font-extrabold { font-weight: 800; }
.font-black { font-weight: 900; }
.font-extrablack { font-weight: 1000; }