.title {

  width: 100%;
  font-size: 15vw;
  line-height: 16vw;
  padding-top: 50vh;
  text-align: center;
  font-family: 'SFPro700';
  color: #FFF;

}
