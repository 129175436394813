@import '/src/styles/apx.scss';

.link_icon {
  
  cursor: pointer;
  $size: $apx-m-24;
  width: $size;
  height: $size;
  margin-right: $apx-m-6;
  position: relative;

  svg, img {

    width: 100%;
    height: 100%;

  }

  &:hover,
  &:focus {

    &::after  {

      opacity: 1;
      z-index: 10;
      visibility: visible;
      transition: opacity .3s;

    }

  }

  &::after {

    content: attr(data-desk);
    position: absolute;
    display: block;
    right: $apx-m-1;
    bottom: $apx-m-20;

    padding: $apx-m-5;
    padding: $apx-m-10;

    background: var(--color-purple);
    color: var(--color-white);
    z-index: 10;

    width: $apx-m-200;
    border-radius: $apx-m-10;

    opacity: 0;
    z-index: -1;
    visibility: hidden;

  }

}

.icon_bg {
    
  background: rgba(101, 103, 241, .16);
  border-radius: $apx-m-3;

  svg { fill: rgb(101, 103, 241); }

}
