@import '../../../../styles/apx.scss';

.button {

  width: 100%;
  height: $apx-m-48;
  margin: 0 $apx-m-16;
  display: flex;
  place-self: center;
  align-items: center;
  justify-content: center;
  font-family: 'SFPro400';
  height: $apx-m-48;
  font-size: $apx-m-16;
  line-height: $apx-m-21;
  border-radius: $apx-m-8;
  color: var(---color-primary-light);
  background: var(--color-gray-lightest);
  border: unset;
  outline: none;
  text-align: center;

  &__white {
  
    background: var(--color-white);
  
  }

  &__orange {
  
    color: var(--color-white);
    background: var(--color-orange);
  
  }

  &__purple {
  
    color: var(--color-white);
    background: var(--color-violet);
  
  }

  &__purple__ghost {
  
    opacity: 0.5;
    color: var(--color-white);
    background: var(--color-violet);
  
  }

  &:disabled {
    opacity: .6;
  }

}