@font-face {

	font-family: "SFPro100";
	src: url("./fonts/SF-Pro-Display-Thin.otf");

}

@font-face {

	font-family: "SFPro200";
	src: url("./fonts/SF-Pro-Display-Ultralight.otf");

}

@font-face {

	font-family: "SFPro300";
	src: url("./fonts/SF-Pro-Display-Light.otf");

}

@font-face {

	font-family: "SFPro400";
	src: url("./fonts/SF-Pro-Display-Regular.otf");

}

@font-face {

	font-family: "SFPro500";
	src: url("./fonts/SF-Pro-Display-Medium.otf");

}

@font-face {

	font-family: "SFPro600";
	src: url("./fonts/SF-Pro-Display-Semibold.otf");

}

@font-face {

	font-family: "SFPro700";
	src: url("./fonts/SF-Pro-Display-Bold.otf");

}

@font-face {

	font-family: "SFPro800";
	src: url("./fonts/SF-Pro-Display-Heavy.otf");

}

@font-face {

	font-family: "SFPro900";
	src: url("./fonts/SF-Pro-Display-Black.otf");

}