@import '../../../../styles/apx.scss';

.head {

  margin-bottom: $apx-m-12;

  &__title {
  
    color: var(--color-gray);
    text-transform: uppercase;
  
  }

  &__filter {
  
    color: var(--color-violet);
    margin-right: $apx-m-8;
    text-transform: uppercase;
  
  }

  &__arrows {
  
    $size: $apx-m-12;
    width: $size;
    height: $size;
  
  }

}

.deals {

  button {
    background: none;
    border: none;
    padding: 0;

    cursor: pointer;    
    width: 100%;

  }

  &__item {

    padding: $apx-m-8 $apx-m-16;
    border-radius: $apx-m-8;
    background: var(--color-white);
    margin-bottom: $apx-m-16;
  
  }
  
  &__textbox {
  
    display: flex;
    align-items: center;
    height: $apx-m-28;
  
  }

  &__titles {
  
    width: $apx-m-175;
  
  }

  &__data {
  
    width: $apx-m-175;

    &__text {

      &__purple {
      
        color: var(--color-purple-light);
      
      }

      &__gray {
      
        color: var(--color-gray);
      
      }
    
    }
  
  }

}